import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const login = () => {
    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/login`, {
          username,
          password,
        })
        .then((res) => {
          if (res.data.error) {
            setError(res.data.error);
          } else {
            setError("");
            setUsername("");
            setPassword("");
            localStorage.setItem("t5kvs24s46a3n", res.data.token);
            navigate("/");
          }
        })
        .catch((err) => {
          setError(err.message);
          console.log("error : ", err);
        });
    } catch (err) {
      setError(err.message);
      console.log("error : ", err);
    }
  };
  return (
    <div className="account-content">
      <div className="login-wrapper d-flex justify-content-center">
        <div className="login-content">
          <div className="login-userset">
            <div className="login-userheading text-center">
              <h3>Sign In</h3>
              <h4>Please login to your account</h4>
            </div>
            {error ? (
              <div className="alert alert-danger p-1" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            <div className="form-login">
              <label>Username</label>
              <div className="form-addons">
                <input
                  type="text"
                  value={username}
                  placeholder="Enter your username"
                  onChange={(e) => setUsername(e.target.value)}
                  tabIndex={1}
                />
              </div>
            </div>
            <div className="form-login">
              <label>Password</label>
              <div className="pass-group">
                <input
                  type="password"
                  value={password}
                  className="pass-input"
                  placeholder="******"
                  onChange={(e) => setPassword(e.target.value)}
                  tabIndex={2}
                />
              </div>
            </div>
            <div className="form-login">
              <button type="button" className="btn btn-login" onClick={login} tabIndex={3}>
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
