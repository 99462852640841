import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { itemContext } from "./itemContext";

const CreateOrder = () => {
  const {
    getOrderItem,
    clearOrderItem,
    orderid,
    customerName,
    issueDate,
    returnDate,
    mobileNo,
    description,
    setOrderid,
    setCustomerName,
    setIssueDate,
    setReturnDate,
    setMobileNo,
    setDescription,
    editOrderId,
    setEditOrderId,
  } = useContext(itemContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [orderidErr, setOrderidErr] = useState();
  const [customerNameErr, setCustomerNameErr] = useState("");
  const [mobileNoErr, setMobileNoErr] = useState("");
  const [issueDateErr, setIssueDateErr] = useState("");
  const [returnDateErr, setReturnDateErr] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const reset = () => {
    setOrderid(new Date().getTime().toString());
    setCustomerName("");
    setMobileNo("");
    setDescription("");
    setIssueDate("");
    setReturnDate("");
    setIssueDateErr("");
    setReturnDateErr("");
    setOrderidErr("");
    setCustomerNameErr("");
    setMobileNoErr("");
    clearOrderItem();
    getOrderItems();
  };

  const createOrder = async () => {
    setError("");
    setSuccess("");
    let temp = true;
    if (!orderid) {
      setOrderidErr("Order id is require.");
      temp = false;
    } else {
      setOrderidErr("");
    }
    if (!customerName) {
      setCustomerNameErr("Customer name is require.");
      temp = false;
    } else {
      setCustomerNameErr("");
    }
    if (mobileNo.length < 10) {
      setMobileNoErr("Mobile number requires 10 digits.");
      temp = false;
    } else {
      setMobileNoErr("");
    }
    if (!issueDate) {
      setIssueDateErr("Issue date is require.");
      temp = false;
    } else {
      if (new Date(issueDate) <= new Date()) {
        setIssueDateErr("Please select any future date.");
        temp = false;
      } else {
        setIssueDateErr("");
      }
    }
    if (!returnDate) {
      setReturnDateErr("Return date is require.");
      temp = false;
    } else {
      if (new Date(returnDate) <= new Date(issueDate)) {
        setReturnDateErr("Please select a return date greater than the issue date.");
        temp = false;
      } else {
        setReturnDateErr("");
      }
    }
    if (temp) {
      if (!editOrderId) {
        try {
          const token = localStorage.getItem("t5kvs24s46a3n");
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}order/create`,
              {
                orderid: orderid,
                customername: customerName,
                mobileno: mobileNo,
                description: description,
                items: products,
                issuedatetime: new Date(issueDate),
                returndatetime: new Date(returnDate),
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
              if (res.data?.token === false) {
                navigate("/login");
              }
              if (res.data.error) {
                setSuccess("");
                setError(res.data.error);
              } else {
                if (res.data.id) {
                  setError("");
                  setSuccess(res.data.message);
                  clearOrderItem();
                  getOrderItems();
                  reset();
                } else {
                  setError("Can not get Id in response.");
                }
              }
            })
            .catch((err) => {
              console.log("error : ", err);
              setError(err.message);
            });
        } catch (err) {
          console.log("error : ", err);
          setError(err.message);
        }
      } else {
        try {
          const token = localStorage.getItem("t5kvs24s46a3n");
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}order/update`,
              {
                id: editOrderId,
                orderid: orderid,
                customername: customerName,
                mobileno: mobileNo,
                description: description,
                items: products,
                issuedatetime: new Date(issueDate),
                returndatetime: new Date(returnDate),
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
              if (res.data?.token === false) {
                navigate("/login");
              }
              if (res.data.error) {
                setSuccess("");
                setError(res.data.error);
              } else {
                if (res.data.id) {
                  setError("");
                  setSuccess(res.data.message);
                  clearOrderItem();
                  getOrderItems();
                  setEditOrderId("");
                  reset();
                } else {
                  setError("Can not get Id in response.");
                }
              }
            })
            .catch((err) => {
              console.log("error : ", err);
              setError(err.message);
            });
        } catch (err) {
          console.log("error : ", err);
          setError(err.message);
        }
      }
    }
  };

  const removeOrderItem = (id) => {
    const data = getOrderItem();
    if (data.length) {
      const newItems = data.filter((item) => item.id !== id);
      localStorage.setItem("o2r8d4e2r6i11t2e8m15", JSON.stringify(newItems));
      getOrderItems();
    }
  };

  const getOrderItems = () => {
    const data = getOrderItem();
    setProducts([...data]);
  };
  const getOrderDetails = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/order/getone/${editOrderId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            // setNotFount(true);
          } else {
            setIssueDate(
              `${new Date(res.data[0].issuedatetime).getFullYear()}-${`${
                new Date(res.data[0].issuedatetime).getMonth() + 1
              }`.padStart(2, 0)}-${`${new Date(res.data[0].issuedatetime).getDate()}`.padStart(
                2,
                0
              )}T${`${new Date(res.data[0].issuedatetime).getHours()}`.padStart(2, 0)}:${`${new Date(
                res.data[0].issuedatetime
              ).getMinutes()}`.padStart(2, 0)}`
            );
            setReturnDate(
              `${new Date(res.data[0].returndatetime).getFullYear()}-${`${
                new Date(res.data[0].returndatetime).getMonth() + 1
              }`.padStart(2, 0)}-${`${new Date(res.data[0].returndatetime).getDate()}`.padStart(
                2,
                0
              )}T${`${new Date(res.data[0].returndatetime).getHours()}`.padStart(2, 0)}:${`${new Date(
                res.data[0].returndatetime
              ).getMinutes()}`.padStart(2, 0)}`
            );
            setCustomerName(res.data[0].customername);
            setMobileNo(res.data[0].mobileno);
            setDescription(res.data[0].description);
            setOrderid(res.data[0].orderid);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
          // setNotFount(true);
        });
    } catch (err) {
      console.log("error : ", err);
      // setNotFount(true);
    }
  };

  useEffect(() => {
    if (editOrderId) {
      getOrderDetails();
    }
  }, [editOrderId]);

  useEffect(() => {
    getOrderItems();
  }, []);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Create Order</h4>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="orderid">Order Id</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="text"
                      className="form-control"
                      id="orderid"
                      placeholder="Order Id"
                      value={orderid}
                      onChange={(e) => setOrderid(e.target.value)}
                      disabled={editOrderId ? true : false}
                    />
                    {orderidErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {orderidErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="cname">Customer Name</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="text"
                      // type="datetime-local"
                      className="form-control"
                      id="cname"
                      placeholder="Customer name"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                    {customerNameErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {customerNameErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="mobileno">Mobile No.</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="tel"
                      // type="datetime-local"
                      className="form-control"
                      id="mobileno"
                      placeholder="0000000000"
                      pattern="[0-9]{10}"
                      required
                      value={mobileNo}
                      onChange={(e) => {
                        const re = /^[0-9]{0,10}$/;

                        if (e.target.value === "" || re.test(e.target.value)) {
                          setMobileNo(e.target.value);
                        }
                      }}
                    />
                    {mobileNoErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {mobileNoErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12"></div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="issuedate">Issue Datetime</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="issuedate"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      disabled={products.length ? true : false}
                    />
                    {issueDateErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {issueDateErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="returndate">Return Datetime</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="returndate"
                      value={returnDate}
                      onChange={(e) => setReturnDate(e.target.value)}
                      disabled={products.length ? true : false}
                    />
                    {returnDateErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {returnDateErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12"></div>
                <div className="col-lg-3 col-sm-6 col-12"></div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="description">Description (Optional)</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <textarea
                      rows={3}
                      className="form-control"
                      value={description}
                      placeholder="Write here."
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 mx-1">
                  <h4 className="my-1">
                    <strong>Items : </strong>
                  </h4>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          className="table datanew dataTable no-footer"
                          id="DataTables_Table_0"
                          role="grid"
                          aria-describedby="DataTables_Table_0_info"
                        >
                          <thead>
                            <tr role="row">
                              <th>Sr. No</th>
                              <th>Product Id</th>
                              <th>Product Name</th>
                              <th>Category</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products && products.length ? (
                              products.map((item, index) => {
                                return (
                                  <tr className="odd" key={index}>
                                    <td className="sorting_1">{index + 1}</td>
                                    <td>{item.pid ? item.pid : "-"}</td>
                                    <td className="productimgname">
                                      {item.pimage ? (
                                        <a type="button" className="product-img">
                                          <img
                                            src={`${process.env.REACT_APP_IMG_PRODUCT + item.pimage}`}
                                            alt="product"
                                          />
                                        </a>
                                      ) : (
                                        <a type="button" className="product-img">
                                          <img src="assets/img/bandhan-logo.png" alt="product" />
                                        </a>
                                      )}

                                      <a type="button">{item.pname ? item.pname : "-"}</a>
                                    </td>
                                    <td>{item.pcategory ? item.pcategory : "-"}</td>
                                    <td>{item.pprice ? item.pprice : 0}</td>
                                    <td>
                                      <button
                                        className="confirm-text"
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        onClick={() => removeOrderItem(item.id)}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"
                                            fill="#EA5455"
                                          />
                                          <path d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z" fill="#EA5455" />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="sorting_1" style={{ textAlign: "center" }}>
                                  No data found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                {error ? (
                  <div className="alert alert-danger p-1 mx-3" role="alert">
                    {error}
                  </div>
                ) : (
                  ""
                )}
                {success ? (
                  <div className="alert alert-success p-1 mx-3" role="alert">
                    {success}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-12">
                  {editOrderId ? (
                    <button className="btn btn-submit me-2" onClick={createOrder}>
                      Update Order
                    </button>
                  ) : (
                    <button className="btn btn-submit me-2" onClick={createOrder}>
                      Create Order
                    </button>
                  )}
                  <button className="btn btn-cancel" onClick={reset}>
                    Clear
                  </button>
                  {editOrderId ? (
                    <button
                      className="btn btn-cancel mx-2"
                      onClick={() => {
                        setEditOrderId("");
                        navigate("/order");
                      }}
                    >
                      Cancle
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default CreateOrder;
