import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const AddOrderItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [orderid, setOrderid] = useState(new Date().getTime().toString());
  const [customerName, setCustomerName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [description, setDescription] = useState("");
  const [products, setProducts] = useState([]);
  const [notFount, setNotFount] = useState(false);
  const [items, setItems] = useState(null);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // const reset = () => {
  //   setOrderid(new Date().getTime().toString());
  //   setCustomerName("");
  //   setMobileNo("");
  //   setDescription("");
  // };

  const getOrderDetails = async () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}order/getone/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            setNotFount(true);
          } else {
            setNotFount(false);
            setOrderid(res.data[0].orderid);
            setCustomerName(res.data[0].customername);
            setMobileNo(res.data[0].mobileno);
            setDescription(res.data[0].description);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
          setNotFount(true);
        });
    } catch (err) {
      console.log("error : ", err);
      setNotFount(true);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Add Order Items</h4>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="orderid">
                      Order Id: <b>{orderid}</b>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="cname">Customer Name: {customerName}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="mobileno">Mobile No.: {mobileNo}</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12"></div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="description">Description: {description}</label>
                  </div>
                </div>
                <div className="col-12 mx-1">
                  <h4 className="my-1">
                    <strong>Items : {"4"}</strong>
                  </h4>
                  <div className="card">
                    <div className="card-body">
                      <table
                        className="table datanew dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Sr. No</th>
                            <th>Product Id</th>
                            <th>Product Name</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Quentity</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products && products.length ? (
                            products.map((item, index) => {
                              return (
                                <tr className="odd" key={index}>
                                  <td className="sorting_1">{index + 1}</td>
                                  <td>{item.pid ? item.pid : "-"}</td>
                                  <td className="productimgname">
                                    <a type="button" className="product-img">
                                      <img src="assets/img/product/product2.jpg" alt="product" />
                                    </a>
                                    <a type="button">{item.pname ? item.pname : "-"}</a>
                                  </td>
                                  <td>{item.category.length ? item.category[0].cname : "-"}</td>
                                  <td>{item.pprice ? item.pprice : 0}</td>
                                  <td>{item.pqty ? item.pqty : 0}</td>
                                  <td>
                                    <button
                                      className="me-3"
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                    >
                                      <svg
                                        width="16"
                                        height="20"
                                        viewBox="0 0 16 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.045 5.401C15.423 5.023 15.631 4.521 15.631 3.987C15.631 3.453 15.423 2.951 15.045 2.573L13.459 0.987001C13.081 0.609001 12.579 0.401001 12.045 0.401001C11.511 0.401001 11.009 0.609001 10.632 0.986001L0 11.585V16H4.413L15.045 5.401ZM12.045 2.401L13.632 3.986L12.042 5.57L10.456 3.985L12.045 2.401ZM2 14V12.415L9.04 5.397L10.626 6.983L3.587 14H2ZM0 18H16V20H0V18Z"
                                          fill="#637381"
                                        />
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className="sorting_1" style={{ textAlign: "center" }}>
                                No data found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {error ? (
                  <div className="alert alert-danger p-1 mx-3" role="alert">
                    {error}
                  </div>
                ) : (
                  ""
                )}
                {success ? (
                  <div className="alert alert-success p-1 mx-3" role="alert">
                    {success}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-12">
                  <button
                    className="btn btn-submit me-2"
                    // onClick={createOrder}
                  >
                    Save
                  </button>
                  {/* <button className="btn btn-cancel" onClick={reset}>
                      Clear
                    </button> */}
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default AddOrderItems;
