import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const EditProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [notFount, setNotFount] = useState(false);
  const [pid, setPid] = useState("");
  const [pname, setPname] = useState("");
  const [pcategory, setPcategory] = useState("");
  const [pprice, setPprice] = useState(0);
  const [ppriceErr, setPpriceErr] = useState("");
  const [pqty, setPqty] = useState(1);
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [pimage, setPimage] = useState("");
  const [pidErr, setPidErr] = useState("");
  const [pnameErr, setPnameErr] = useState("");
  const [pcategoryErr, setPcategoryErr] = useState("");
  const [pqtyErr, setPqtyErr] = useState("");
  const [sizeErr, setSizeErr] = useState("");
  const [pimageErr, setPimageErr] = useState("");
  const [categories, setCategories] = useState([]);
  const pimageref = useRef(null);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const reset = () => {
    setPidErr("");
    setPname("");
    setPnameErr("");
    setPcategory("");
    setPcategoryErr("");
    setPprice(0);
    setSize("");
    setDescription("");
    setSizeErr("");
    setPpriceErr("");
    // setPqty(1);
    setPcategoryErr("");
    setPimage("");
    setPimageErr("");
    setError("");
    if (pimageref.current) {
      pimageref.current.value = "";
      pimageref.current.type = "text";
      pimageref.current.type = "file";
    }
  };

  const updateProduct = async () => {
    setError("");
    setSuccess("");
    let temp = true;
    if (!pid) {
      setPidErr("Product id is require.");
      temp = false;
    } else {
      setPidErr("");
    }
    if (!pname) {
      setPnameErr("Product name is require.");
      temp = false;
    } else {
      setPnameErr("");
    }
    if (!pcategory) {
      setPcategoryErr("Product category is require.");
      temp = false;
    } else {
      setPcategoryErr("");
    }
    if (pprice <= 0) {
      setPpriceErr("The Product price must be greater than 0.");
      temp = false;
    } else {
      setPpriceErr("");
    }
    // if (pqty <= 0) {
    //   setPqtyErr("The Product qty must be greater than 0.");
    //   temp = false;
    // } else {
    //   setPqtyErr("");
    // }
    // if (size) {
    //   setSizeErr("The Product qty must be greater than 0.");
    //   temp = false;
    // } else {
    //   setSizeErr("");
    // }
    // if (!pimage) {
    //   setPimageErr("Product image is require.");
    // temp = false;
    // } else {
    //   setPimageErr("");
    // }
    if (temp) {
      if (productDetails._id) {
        try {
          const token = localStorage.getItem("t5kvs24s46a3n");
          let formData = new FormData();
          formData.append("id", productDetails._id);
          formData.append("pid", pid);
          formData.append("pname", pname);
          formData.append("pcategory", pcategory);
          formData.append("pprice", pprice);
          formData.append("size", size);
          formData.append("description", description);
          formData.append("file", pimage);
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}product/update`, formData, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              if (res.data?.token === false) {
                navigate("/login");
              }
              if (res.data.error) {
                setSuccess("");
                setError(res.data.error);
              } else {
                setError("");
                setSuccess(res.data.message);
                reset();
                navigate("/products");
              }
            })
            .catch((err) => {
              console.log("error : ", err);
              setError(err.message);
            });
        } catch (err) {
          console.log("error : ", err);
          setError(err.message);
        }
      } else {
        setError("Product _id is not found in product details.");
      }
    }
  };

  const getProductDetails = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}product/getone/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            setNotFount(true);
          } else {
            setNotFount(false);
            setProductDetails(res.data[0]);
            setPid(res.data[0].pid);
            setPname(res.data[0].pname);
            setPprice(res.data[0].pprice);
            setSize(res.data[0].size);
            setDescription(res.data[0].description);
            // setPqty(res.data[0].pqty);
            setPcategory(res.data[0].pcategory);
            setPimage(res.data[0].pimage);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
          setNotFount(true);
        });
    } catch (err) {
      console.log("error : ", err);
      setNotFount(true);
    }
  };

  const getCategory = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}category/getall`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            setError(res.data.error);
          } else {
            setCategories(res.data);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getProductDetails();
    getCategory();
  }, []);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Edit Product</h4>
              <h6>Edit current product</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              {notFount ? (
                <h2>Product Not Found.</h2>
              ) : (
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="pid">Product Id</label>
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <input
                        type="text"
                        className="form-control"
                        id="pid"
                        placeholder="Product Id"
                        value={pid}
                        // onChange={(e) => setPid(e.target.value)}
                        disabled
                      />
                      {pidErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {pidErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="pname">Product Name</label>
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <input
                        type="text"
                        // type="datetime-local"
                        className="form-control"
                        id="pname"
                        placeholder="Product name"
                        value={pname}
                        onChange={(e) => setPname(e.target.value)}
                      />
                      {pnameErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {pnameErr}
                        </small>
                      ) : (
                        ""
                      )}
                      {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="pcategory">Category</label>
                      <select
                        className="form-control"
                        id="pcategory"
                        // ref={pcatref}
                        value={pcategory}
                        onChange={(e) => setPcategory(e.target.value)}
                      >
                        <option value="">-Select-</option>
                        {categories && categories?.length
                          ? categories?.map((item, index) => {
                              return (
                                <option key={item?.cid} value={item?.cid}>
                                  {item?.cname}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      {pcategoryErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {pcategoryErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12"></div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="pprice">Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="pprice"
                        aria-describedby="emailHelp"
                        placeholder="Price"
                        value={pprice}
                        onChange={(e) => setPprice(e.target.value)}
                      />
                      {ppriceErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {ppriceErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="pqty">Qty</label>
                        <input
                          type="number"
                          className="form-control"
                          id="pqty"
                          aria-describedby="emailHelp"
                          placeholder="Qty"
                          value={pqty}
                          onChange={(e) => setPqty(e.target.value)}
                        />
                        {pqtyErr ? (
                          <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                            {pqtyErr}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div> */}
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="size">Size</label>
                      <select
                        className="form-control"
                        id="size"
                        // ref={pcatref}
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <option value="" selected>
                          -Select-
                        </option>
                        <option value="32">32</option>
                        <option value="34">34</option>
                        <option value="36">36</option>
                        <option value="38">38</option>
                        <option value="40">40</option>
                        <option value="42">42</option>
                        <option value="44">44</option>
                        <option value="46">46</option>
                        <option value="48">48</option>
                        <option value="50">50</option>
                        <option value="52">52</option>
                        <option value="54">54</option>
                        <option value="56">56</option>
                        <option value="58">58</option>
                        <option value="60">60</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12"></div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="description">Description (Optional)</label>
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <textarea
                        rows={3}
                        className="form-control"
                        value={description}
                        placeholder="Write here."
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12"></div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor="pimage" className="form-label">
                        Product Image
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="pimage"
                        // value={pimage}
                        ref={pimageref}
                        onChange={(e) => setPimage(e.target.files[0])}
                      />
                      {pimageErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {pimageErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {error ? (
                    <div className="alert alert-danger p-1 mx-3" role="alert">
                      {error}
                    </div>
                  ) : (
                    ""
                  )}
                  {success ? (
                    <div className="alert alert-success p-1 mx-3" role="alert">
                      {success}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" onClick={updateProduct}>
                      Update
                    </button>
                    <button className="btn btn-cancel" onClick={reset}>
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
