import React, { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaUser } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { itemContext } from "../pages/itemContext";

const Header = () => {
  const { toggleMenu, setToggleMenu } = useContext(itemContext);
  const navigate = useNavigate();
  const verifylogin = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      if (token) {
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}user/verify`, {
            token: token,
          })
          .then((res) => {
            if (res.data.error) {
              console.log(res.data.error);
              localStorage.removeItem("t5kvs24s46a3n");
              navigate("/");
            }
          })
          .catch((err) => {
            console.log("error : ", err);
            navigate("/login");
          });
      } else {
        localStorage.removeItem("t5kvs24s46a3n");
        navigate("/login");
      }
    } catch (err) {
      localStorage.removeItem("t5kvs24s46a3n");
      console.log("error : ", err);
      navigate("/login");
    }
  };
  const logout = async () => {
    try {
      localStorage.removeItem("t5kvs24s46a3n");
      navigate("/login");
    } catch (err) {
      console.log("error : ", err);
    }
  };
  useEffect(() => {
    verifylogin();
  }, []);
  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left active">
          <Link to="/" className="logo logo-normal">
            <img src="/assets/img/bandhan-logo.png" alt="" />
          </Link>
          <Link to="/" className="logo logo-white">
            <img src="/assets/img/bandhan-logo.png" alt="" />
          </Link>
          <Link to="/" className="logo-small">
            <img src="/assets/img/bandhan-logo.png" alt="" />
          </Link>
          <Link id="toggle_btn">
            <MdKeyboardDoubleArrowLeft />
          </Link>
        </div>
        {/* /Logo */}
        <button
          id="mobile_btn"
          type="button"
          className="mobile_btn"
          style={{ background: "transparent", border: "none", outline: "none" }}
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </button>
        {/* Header Menu */}
        <ul className="nav user-menu">
          <li className="nav-item dropdown has-arrow main-drop">
            <a type="button" className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
              <span className="user-info">
                <span className="user-letter">
                  <FaUser />
                </span>
              </span>
            </a>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <hr className="m-0" />
                <Link className="dropdown-item" to="/change-password">
                  {" "}
                  <i className="me-2" data-feather="user" /> Change Password
                </Link>
                <hr className="m-0" />
                <a type="button" className="dropdown-item logout pb-0" onClick={logout}>
                  <img src="/assets/img/icons/log-out.svg" className="me-2" alt="img" />
                  Logout
                </a>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <a
            type="button"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FiMoreVertical />
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="/change-password">
              Change Password
            </Link>

            <Link to="/login" className="dropdown-item">
              Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
      {/* Header */}
    </>
  );
};

export default Header;
