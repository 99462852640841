import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { itemContext } from "./itemContext";
import { FiPlusCircle } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FiChevronsRight } from "react-icons/fi";

const InWash = () => {
  const { addOrderItem, issueDate, returnDate, toastSuccess } = useContext(itemContext);
  const [searchParams] = useSearchParams();
  const cateid = searchParams.get("cateid") ? searchParams.get("cateid") : "";
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [washData, setWashData] = useState(null);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const getWashProducts = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}product/getinwash?currentpage=${currentPage}&size=${pageSize}&search=${search}&cateid=${cateid}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
          } else {
            setWashData(res.data.items);
            setPages(res.data.pages);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  useEffect(() => {
    getWashProducts();
  }, [pageSize, currentPage]);

  const changeWashStatus = async (id) => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}product/changewashstatus/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            getWashProducts();
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  useEffect(() => {
    getWashProducts();
  }, [search]);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Wash Product List</h4>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  {/* <div className="search-path">
                      <a className="btn btn-filter" id="filter_search">
                        <img src="assets/img/icons/filter.svg" alt="img" />
                        <span>
                          <img src="assets/img/icons/closes.svg" alt="img" />
                        </span>
                      </a>
                    </div> */}
                  <div className="search-input">
                    <button className="btn btn-searchset" onClick={getWashProducts}>
                      <FiSearch size={20} color="#ff9f43" />
                    </button>
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <table
                    className="table datanew dataTable no-footer"
                    id="DataTables_Table_0"
                    role="grid"
                    aria-describedby="DataTables_Table_0_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No</th>
                        <th>Product Id</th>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Size</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {washData && washData.length ? (
                        washData.map((item, index) => {
                          let ind = pageSize * currentPage - pageSize + (index + 1);
                          return (
                            <tr className="odd" key={index}>
                              <td className="sorting_1">{ind}</td>
                              <td>{item?.pid ? item?.pid : "-"}</td>
                              <td className="productimgname">
                                {item?.pimage ? (
                                  <a type="button" className="product-img">
                                    <img
                                      src={`${process.env.REACT_APP_IMG_PRODUCT + item.pimage}`}
                                      alt="product"
                                    />
                                  </a>
                                ) : (
                                  <a type="button" className="product-img">
                                    <img src="assets/img/bandhan-logo.png" alt="product" />
                                  </a>
                                )}
                                <a type="button">{item.pname ? item.pname : "-"}</a>
                              </td>
                              <td>{item?.category?.length ? item?.category[0]?.cname : "-"}</td>
                              <td>{item?.pprice ? item?.pprice : 0}</td>
                              <td>{item?.size ? item?.size : "-"}</td>
                              <td>{item?.description ? item?.description : "-"}</td>
                              <td>
                                {item?.inwash ? (
                                  <button
                                    className="btn btn-danger btn-sm mx-2"
                                    onClick={() => changeWashStatus(item?._id)}
                                  >
                                    Wash Out
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-sm mx-2"
                                    onClick={() => changeWashStatus(item?._id)}
                                  >
                                    Wash In
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7} className="sorting_1" style={{ textAlign: "center" }}>
                            No data found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default InWash;
