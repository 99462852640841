import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryDetails, setCroductDetails] = useState(null);
  const [categories, setCategories] = useState(null);
  const [notFount, setNotFount] = useState(false);
  const [cid, setCid] = useState("");
  const [cname, setCname] = useState("");
  const [cimage, setCimage] = useState("");
  const [cidErr, setCidErr] = useState("");
  const [cnameErr, setCnameErr] = useState("");
  const [cimageErr, setCimageErr] = useState("");
  const cimageref = useRef(null);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const reset = () => {
    setCidErr("");
    setCname("");
    setCnameErr("");
    setCimage("");
    setCimageErr("");
    setError("");
    if (cimageref.current) {
      cimageref.current.value = "";
      cimageref.current.type = "text";
      cimageref.current.type = "file";
    }
  };

  const updateCategory = async () => {
    setError("");
    setSuccess("");
    let temp = true;
    if (!cid) {
      setCidErr("Category id is require.");
      temp = false;
    } else {
      setCidErr("");
    }
    if (!cname) {
      setCnameErr("Category name is require.");
      temp = false;
    } else {
      setCnameErr("");
    }
    // if (!cimage) {
    //   setCimageErr("Category image is require.");
    // temp = false;
    // } else {
    //   setCimageErr("");
    // }
    if (temp) {
      if (categoryDetails._id) {
        try {
          const token = localStorage.getItem("t5kvs24s46a3n");
          let formData = new FormData();
          formData.append("id", categoryDetails._id);
          formData.append("cid", cid);
          formData.append("cname", cname);
          formData.append("file", cimage);
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}category/update`, formData, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              if (res.data?.token === false) {
                navigate("/login");
              }
              if (res.data.error) {
                setSuccess("");
                setError(res.data.error);
              } else {
                setError("");
                setSuccess(res.data.message);
                reset();
                navigate("/category");
              }
            })
            .catch((err) => {
              console.log("error : ", err);
              setError(err.message);
            });
        } catch (err) {
          console.log("error : ", err);
          setError(err.message);
        }
      } else {
        setError("Category _id is not found in product details.");
      }
    }
  };

  const getCategoryDetails = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}category/getone/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            setNotFount(true);
          } else {
            setNotFount(false);
            setCroductDetails(res.data[0]);
            setCid(res.data[0].cid);
            setCname(res.data[0].cname);
            setCimage(res.data[0].cimage);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
          setNotFount(true);
        });
    } catch (err) {
      console.log("error : ", err);
      setNotFount(true);
    }
  };

  const getCategory = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}category/getall`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            setError(res.data.error);
          } else {
            setCategories(res.data);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getCategoryDetails();
    getCategory();
  }, []);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Edit Category</h4>
              <h6>Edit current Category</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              {notFount ? (
                <h2>Categories Not Found.</h2>
              ) : (
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="cid">Category Id</label>
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <input
                        type="text"
                        className="form-control"
                        id="cid"
                        placeholder="Product Id"
                        value={cid}
                        // onChange={(e) => setCid(e.target.value)}
                        disabled
                      />
                      {cidErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {cidErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="cname">Category Name</label>
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <input
                        type="text"
                        // type="datetime-local"
                        className="form-control"
                        id="cname"
                        placeholder="Product name"
                        value={cname}
                        onChange={(e) => setCname(e.target.value)}
                      />
                      {cnameErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {cnameErr}
                        </small>
                      ) : (
                        ""
                      )}
                      {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12"></div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor="cimage" className="form-label">
                        Category Image
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="cimage"
                        // value={cimage}
                        ref={cimageref}
                        onChange={(e) => setCimage(e.target.files[0])}
                      />
                      {cimageErr ? (
                        <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                          {cimageErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {error ? (
                    <div className="alert alert-danger p-1 mx-3" role="alert">
                      {error}
                    </div>
                  ) : (
                    ""
                  )}
                  {success ? (
                    <div className="alert alert-success p-1 mx-3" role="alert">
                      {success}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" onClick={updateCategory}>
                      Update
                    </button>
                    <button className="btn btn-cancel" onClick={reset}>
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
