import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(
    localStorage.getItem("t5kvs24s46a3n") ? localStorage.getItem("t5kvs24s46a3n") : ""
  );

  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confNewPassword, setConfNewPassword] = useState();
  const [oldPasswordErr, setOldPasswordErr] = useState();
  const [newPasswordErr, setNewPasswordErr] = useState();
  const [confNewPasswordErr, setConfNewPasswordErr] = useState();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const reset = () => {
    setOldPassword("");
    setNewPassword("");
    setConfNewPassword("");
    setOldPasswordErr("");
    setNewPasswordErr("");
    setConfNewPasswordErr("");
  };

  const chnagePassword = async () => {
    setError("");
    setSuccess("");
    let temp = true;
    if (!oldPassword) {
      setOldPasswordErr("Old Password is require.");
      temp = false;
    } else {
      setOldPasswordErr("");
    }
    if (!newPassword) {
      setNewPasswordErr("New Password is require.");
      temp = false;
    } else {
      if (newPassword.length < 6) {
        setNewPasswordErr("Minimum 6 charachter require.");
        temp = false;
      } else {
        setNewPasswordErr("");
      }
    }
    if (!confNewPassword) {
      setConfNewPasswordErr("Confirm New Password is require.");
      temp = false;
    } else {
      if (newPassword === confNewPassword) {
        setConfNewPasswordErr("");
      } else {
        setConfNewPasswordErr("New password and confirm new password not match.");
        temp = false;
      }
    }
    if (temp) {
      if (token) {
        try {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}user/changepassword`,
              {
                token,
                oldPassword,
                newPassword,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
              if (res.data?.token === false) {
                navigate("/login");
              }
              if (res.data.error) {
                setSuccess("");
                setError(res.data.error);
              } else {
                setError("");
                setSuccess(res.data.message);
                reset();
              }
            })
            .catch((err) => {
              console.log("error : ", err);
              setError(err.message);
            });
        } catch (err) {
          console.log("error : ", err);
          setError(err.message);
        }
      } else {
        setError("Token not found.");
      }
    }
  };

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Change Password</h4>
              <h6>Create new Password</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="cid">Old Password</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="password"
                      className="form-control"
                      id="cid"
                      placeholder="******"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    {oldPasswordErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {oldPasswordErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="cname">New Password</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="password"
                      // type="datetime-local"
                      className="form-control"
                      id="cname"
                      placeholder="******"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {newPasswordErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {newPasswordErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="cname">Confirm New Password</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="password"
                      // type="datetime-local"
                      className="form-control"
                      id="cname"
                      placeholder="******"
                      value={confNewPassword}
                      onChange={(e) => setConfNewPassword(e.target.value)}
                    />
                    {confNewPasswordErr ? (
                      <small id="emailHelp" style={{ color: "#FF0000", fontSize: "0.8rem" }}>
                        {confNewPasswordErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {error ? (
                <div className="alert alert-danger p-1 mx-1" role="alert">
                  {error}
                </div>
              ) : (
                ""
              )}
              {success ? (
                <div className="alert alert-success p-1 mx-1" role="alert">
                  {success}
                </div>
              ) : (
                ""
              )}
              <div className="col-lg-12">
                <button className="btn btn-submit me-2" onClick={chnagePassword}>
                  Save
                </button>
                <button className="btn btn-cancel" onClick={reset}>
                  Clear
                </button>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
