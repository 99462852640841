import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const itemContext = createContext();

const ItemContext = ({ children }) => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [editOrderId, setEditOrderId] = useState("");
  const [orderid, setOrderid] = useState(new Date().getTime().toString());
  const [customerName, setCustomerName] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [description, setDescription] = useState("");
  const addOrderItem = (item) => {
    try {
      const data = getOrderItem();
      if (data.length) {
        const token = localStorage.getItem("t5kvs24s46a3n");
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}order/checkavailability`,
            {
              issuedate: issueDate,
              returndate: returnDate,
              id: item.id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.data?.token === false) {
              navigate("/login");
            }
            if (res.data.available) {
              const find = data.find((item1) => item1.id === item.id);
              if (find) {
                toastError(`${find.pname} has already been added..`, 2000);
              } else {
                const newItems = [...data, item];
                localStorage.setItem("o2r8d4e2r6i11t2e8m15", JSON.stringify(newItems));
                toastSuccess(`${item.pname} added.`, 2000);
              }
            } else {
              toastError(res.data.message, 2000);
            }
          })
          .catch((err) => {
            console.log("error : ", err);
            toastError(err.message, 2000);
          });
      } else {
        const arr = [];
        const token = localStorage.getItem("t5kvs24s46a3n");
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}order/checkavailability`,
            {
              issuedate: issueDate,
              returndate: returnDate,
              id: item.id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.data?.token === false) {
              navigate("/login");
            }
            if (res.data.available) {
              const find = arr.find((item1) => item1.id === item.id);
              if (find) {
                toastError(`${find.pname} has already been added..`, 2000);
              } else {
                const items = [...arr, item];
                localStorage.setItem("o2r8d4e2r6i11t2e8m15", JSON.stringify(items));
                toastSuccess(`${item.pname} added.`, 2000);
              }
            } else {
              toastError(res.data.message, 2000);
            }
          })
          .catch((err) => {
            console.log("error : ", err);
            toastError(err.message, 2000);
          });
      }
    } catch (err) {
      console.log("error : ", err);
      toastError(err.message, 2000);
    }
  };
  const clearOrderItem = () => {
    localStorage.removeItem("o2r8d4e2r6i11t2e8m15");
  };
  const getOrderItem = () => {
    const data = localStorage.getItem("o2r8d4e2r6i11t2e8m15");
    if (data) {
      try {
        const items = JSON.parse(data);
        return items;
      } catch (err) {
        return [];
      }
    } else {
      return [];
    }
  };

  const toastSuccess = (text, time) => {
    toast.success(text, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };
  const toastError = (text, time) => {
    toast.error(text, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };
  const toastInfo = (text, time) => {
    toast.info(text, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const formatDate = (date1) => {
    const date = new Date(date1);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = Number(date.getHours()) % 12;
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const form = Number(date.getHours()) >= 12 ? "PM" : "AM";
    return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds + " " + form;
  };

  useEffect(() => {
    // getOrderItem();
  }, []);

  return (
    <itemContext.Provider
      value={{
        getOrderItem,
        addOrderItem,
        clearOrderItem,
        orderid,
        customerName,
        issueDate,
        returnDate,
        mobileNo,
        description,
        setOrderid,
        setCustomerName,
        setIssueDate,
        setReturnDate,
        setMobileNo,
        setDescription,
        toastSuccess,
        toastError,
        toastInfo,
        formatDate,
        toggleMenu,
        setToggleMenu,
        setEditOrderId,
        editOrderId,
      }}
    >
      {children}
    </itemContext.Provider>
  );
};

export default ItemContext;
