import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FiChevronsRight } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";

const Category = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [category, setCategory] = useState(null);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const getCategory = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}category/get?currentpage=${currentPage}&size=${pageSize}&search=${search}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setCategory(res.data.items);
            setPages(res.data.pages);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  useEffect(() => {
    getCategory();
  }, [pageSize, currentPage]);

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  const deleteCategory = async () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}category/delete/${deleteId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setDeleteId("");
            setDeleteModal(false);
            getCategory();
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getCategory();
  }, [search]);

  return (
    <div>
      {/* Main Wrapper */}

      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Category List</h4>
              <h6>Manage your Categories</h6>
            </div>
            <div className="page-btn">
              <Link to="/add-category" className="btn btn-added">
                Add New Category
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  {/* <div className="search-path">
                      <a className="btn btn-filter" id="filter_search">
                        <img src="assets/img/icons/filter.svg" alt="img" />
                        <span>
                          <img src="assets/img/icons/closes.svg" alt="img" />
                        </span>
                      </a>
                    </div> */}
                  <div className="search-input">
                    <button className="btn btn-searchset" onClick={getCategory}>
                      <FiSearch size={20} color="#ff9f43" />
                    </button>
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <table
                    className="table datanew dataTable no-footer"
                    id="DataTables_Table_0"
                    role="grid"
                    aria-describedby="DataTables_Table_0_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No</th>
                        <th>Category Id</th>
                        <th>Category Name</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {category && category.length ? (
                        category.map((item, index) => {
                          let ind = pageSize * currentPage - pageSize + (index + 1);
                          return (
                            <tr className="odd" key={index}>
                              <td className="sorting_1">{ind}</td>
                              <td>{item.cid ? item.cid : "-"}</td>
                              <td className="productimgname">
                                {item?.cimage ? (
                                  <a type="button" className="product-img">
                                    <img
                                      src={`${process.env.REACT_APP_IMG_CATEGORY + item.cimage}`}
                                      alt="category"
                                    />
                                  </a>
                                ) : (
                                  <a type="button" className="product-img">
                                    <img src="assets/img/bandhan-logo.png" alt="category" />
                                  </a>
                                )}
                                <a type="button">{item.cname ? item.cname : "-"}</a>
                              </td>
                              <td>{item.pcount ? item.pcount : 0}</td>
                              <td>
                                <button
                                  className="me-2"
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  onClick={() => navigate(`/edit-category/${item._id}`)}
                                >
                                  <svg
                                    width="16"
                                    height="20"
                                    viewBox="0 0 16 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.045 5.401C15.423 5.023 15.631 4.521 15.631 3.987C15.631 3.453 15.423 2.951 15.045 2.573L13.459 0.987001C13.081 0.609001 12.579 0.401001 12.045 0.401001C11.511 0.401001 11.009 0.609001 10.632 0.986001L0 11.585V16H4.413L15.045 5.401ZM12.045 2.401L13.632 3.986L12.042 5.57L10.456 3.985L12.045 2.401ZM2 14V12.415L9.04 5.397L10.626 6.983L3.587 14H2ZM0 18H16V20H0V18Z"
                                      fill="#637381"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="confirm-text"
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  onClick={() => handleDelete(item._id)}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"
                                      fill="#EA5455"
                                    />
                                    <path d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z" fill="#EA5455" />
                                  </svg>
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  onClick={() => navigate(`/products?cateid=${item.cid}`)}
                                >
                                  View <FiChevronsRight />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="sorting_1" style={{ textAlign: "center" }}>
                            No data found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Modal show={deleteModal} onHide={handleCloseDeleteModal} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete?</Modal.Body>
        <Modal.Footer>
          <Button className="p-2" variant="outline-secondary" size="sm" onClick={handleCloseDeleteModal}>
            Close
          </Button>
          <Button className="p-2" variant="outline-danger" size="sm" onClick={deleteCategory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Category;
